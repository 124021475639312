<script>
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data: {},
				title_head: '',
				topicTerkait: {},
				relatedProduct: {},
			}
		},
		computed: {
			mySlug() {
				return this.$route.params.slug
			},
			mySub() {
				return this.$route.params.sub
			}
		},
		async mounted() {
			await this.ready();
			this.getArticle()
			setTimeout(() => {
				$("a[href$='informasi-kesehatan']").closest("li").addClass("active")
			}, 300)
		},
		methods: {
			getArticle() {
				this.loadingOverlay = true
				let params = Gen.clone(this.$route.query)
				params.slug = this.mySlug
				params.sub = this.mySub
				Gen.apirest("/health-article-list", params, (err, resp) => {
					this.loadingOverlay = false
					if (err) console.log(err)
					if (resp.code == 404) return this.$router.push({
						name: '404'
					})
					this.data = resp.data
					this.title_head = resp.title_head
					this.topicTerkait = resp.topicTerkait
					this.relatedProduct = resp.relatedProduct
				})
			},
			changePage(page = 1) {
				this.$router.push({
					query: Object.assign(Gen.clone(this.$route.query), {
						page: page
					})
				})
			},
			clickShop(v) {
				Gen.apirest("/add-count-shop", v, (err) => {
					this.loadingOverlay = false
				}, "POST")
			}
		},
		watch: {
			$route() {
				this.getArticle()
				setTimeout(() => {
					$("a[href$='informasi-kesehatan']").closest("li").addClass("active")
				}, 300)
			}
		}
	};
</script>
<template>
	<section id="content">
		<div class="content-wrap">
			<section class="section nobg nomargin">
				<div class="container">
					<h3 v-if="$route.name!='Search'">{{title_head}} </h3>
					<h3 v-else-if="$route.name=='Search' && data.total > 0">{{data.total}} hasil ditemukan untuk
						kata kunci "{{$route.query.search}}"</h3>
					<div class="row" v-if="data&&data.total">
						<div class="col-md-8">
							<div class="article-list">
								<div class="article-item" v-for="(v,k) in data.data" :key="k">
									<article class="row">
										<div class="col-md-5">
											<router-link :to="{name:'DetailHealthArticle',params:{sub:v.sub,slug:v.mh_slug}}">
												<img :src="uploader(v.mh_image) || assets('fo_images', 'article_alter.jpg')" :alt="v.mh_title" class="article-img">
											</router-link>
										</div>
										<div class="col-md-7">
											<router-link :to="{name:'DetailHealthArticle',params:{sub:v.sub,slug:v.mh_slug}}">
												<div class="article-desc">
													<h3>{{v.mh_title}}</h3>
													<p>{{v.sortDesc}}</p>
													<div class="writer-block">
														<span v-if="v.mh_author"><i class="icon-user21"></i>
															{{v.mh_author}}</span>
														<!-- <span><i class="icon-calendar21"></i>
															{{v.datePublish}}</span> -->
													</div>
												</div>
											</router-link>
										</div>
									</article>
								</div>
							</div>

							<Pagination v-if="data" :data="data" :limit="2" @pagination-change-page="changePage"
								class="pagination pagination-transparent pagination-circle justify-content-center mb-2 mb-md-0">
								<i slot="prev-nav" class="icon-angle-left"></i>
								<i slot="next-nav" class="icon-angle-right"></i>
							</Pagination>
						</div>
						<div class="col-md-4">
							<div class="related-article" v-if="topicTerkait&&topicTerkait.length&&$route.name!='Search'">
								<h3>Topik Lainnya</h3>
								<router-link :to="{name:'HealthInfoDetailArticle', params:{slug:mySlug,sub:tp.mhc_slug}}"
									class="ra-item px-3 py-3 ra-topic" v-for="(tp,kp) in topicTerkait" :key="kp"
									:style="'background-image:url('+uploader(tp.mhc_image)+')'">
									<div class="row align-items-center">
										<div class="col-4">
											<img :src="uploader(tp.mhc_logo)" :alt="tp.mhc_name">
										</div>
										<div class="col-8">
											<div class="ra-desc">
												<h4 class="mb-0">{{tp.mhc_name}}</h4>
											</div>
										</div>
									</div>
								</router-link>
							</div>
							<div class="related" v-if="relatedProduct&&relatedProduct.length">
								<h3>Produk Terkait</h3>
								<div class="row" v-if="relatedProduct.length>0">
									<div class="col-6 col-md-12 col-lg-6" v-for="(vr,kr) in relatedProduct" :key="kr">
										<div class="r-prod">
											<router-link :to="{name:'ProductsDetail',params:{slug:vr.mp_slug}}">
												<img :src="uploader(vr.mp_image)" :alt="vr.mp_title">
												<h4>{{vr.mp_title}}</h4>
											</router-link>
											<a @click="clickShop(vr)" :href="vr.mp_link_shop" target="_blank"
												class="primary-btn sm-btn">Beli</a>
										</div>
									</div>
								</div>
								<div class="text-center" v-else>
									<h4>Tidak ada Produk Terkait</h4>
								</div>
							</div>
						</div>
					</div>

					<div v-else class="center py-5 py-lg-0">
						<div class="row justify-content-center">
							<div class="col-md-6 col-lg-4">
								<img :src="assets('fo_images', 'empty.svg')" alt="No Results Found">
							</div>
						</div>
						<h3 v-if="$route.name=='Search'">Tidak ada hasil yang ditemukan untuk kata kunci
							"{{$route.query.search}}"</h3>
						<h3 v-else>Belum ada Artikel di kategori ini</h3>
					</div>
				</div>
			</section>
		</div>

	</section><!-- #content end -->
</template>